/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {Injectable} from '@angular/core';
import {KeycloakCustomService} from '../auth/keycloak.custom.service';
import {GridActionEnum} from '../../component/shared/grid/grid.component.constants';
import {LeftMenuParamWindowType} from '../../models/menus/left-menu.model';
import {Utils} from './utils';
import {GroupControllerService} from '../directory_service/api/groupController.service';
import {first} from 'rxjs/operators';
import {GroupDto} from '../directory_service/model/groupDto';
import {AppConfigService} from '../../config/app-config.service';
import {KeycloakTokenModel} from '../../models/auth/keycloak-token.model';
import {AssetDto} from '../directory_service/model/assetDto';
import {STATIC_I18N} from '../generated/translate_service/translate.constants';
import {TranslateService} from '@ngx-translate/core';
import GroupTypeEnum = GroupDto.GroupTypeEnum;


export const ADMIN_ROLE_NAME: string = 'ADMIN';
export const MEMBER_ROLE_NAME: string = 'MEMBER';
export const MANAGER_ROLE_NAME: string = 'MANAGER';
export const OPERATOR_ROLE_NAME: string = 'OPERATOR';
export const USER_ROLE_NAME: string = 'USER';

const ROLE_SEPARATOR: string = ',';
const CREATOR_ROLE_NAME: string = 'CREATE'.toUpperCase();
const TEAM_NAME: string = 'TEAM'.toUpperCase();
const FULL_ADMIN_CREATOR_ROLE_NAME: string = TEAM_NAME + ROLE_SEPARATOR + CREATOR_ROLE_NAME;
const OLD_ROLE_SEPARATOR: string = '|';
const ORG_NAME: string = 'ORG'.toUpperCase();

// const GROUP_NAME_HITEC: string = 'HITEC';
// const GROUP_NAME_SES: string = 'SES';
const GROUP_NAME_CGDIS: string = 'CGDIS';
// const GROUP_NAME_HIT: string = 'HIT';
// const GROUP_NAME_MAE: string = 'MAE';
const GROUP_NAME_MFEA: string = 'MFEA';
// const GROUP_NAME_WFP: string = 'WFP';
// const GROUP_NAME_UNDP: string = 'UNDP';
// const GROUP_NAME_UNSECRETARIAT: string = 'UN secretariat';
// const GROUP_NAME_UNRWA: string = 'UNRWA';
// const GROUP_NAME_UNFPA: string = 'UNFPA';
// const GROUP_NAME_ITU: string = 'ITU';
// const GROUP_NAME_UNWOMEN: string = 'UN Women';
// const GROUP_NAME_UNICEF: string = 'UNICEF';
// const GROUP_NAME_UNHCR: string = 'UNHCR';
// const GROUP_NAME_IOM: string = 'IOM';
// const GROUP_NAME_WHO: string = 'WHO';
// const GROUP_NAME_ERICSSON: string = 'ERICSSON';
// const DOMAIN_UNSECRETARIA: string = "un";
// const DOMAIN_UNWOMEN: string = "unwomen";

const ACTIONS_FOR_ANYONE = [
  GridActionEnum.singleJoinAction,
  GridActionEnum.singleCancelJoinAction,
  GridActionEnum.singleLeaveAction,
  GridActionEnum.singleRejectAction,
  GridActionEnum.singleAcceptAction
]
const ACTIONS_FOR_QUERYING = [
  GridActionEnum.singleViewAction,
  GridActionEnum.multipleReadOnlyCustomAction1,
  GridActionEnum.multipleReadOnlyCustomAction2,
  GridActionEnum.multipleReadOnlyCustomAction3,
  GridActionEnum.multipleReadOnlyCustomAction4,
  GridActionEnum.multipleReadOnlyCustomAction5
]
const ACTIONS_FOR_MODIFYING = [
  GridActionEnum.singleEditAction,
  GridActionEnum.singleCreateAction,
  GridActionEnum.singleRemoveAction,
  GridActionEnum.singleEditableCustomAction1,
  GridActionEnum.singleEditableCustomAction2,
  GridActionEnum.singleEditableCustomAction3,
  GridActionEnum.singleEditableCustomAction4,
  GridActionEnum.singleEditableCustomAction5
]

export enum KeycloakKindEnum {
  Group = 'Group',
  Asset = 'Asset',
  Service = 'Service',
}

export interface KeycloakRolesDTO {
  kind?: KeycloakKindEnum;
  type: GroupTypeEnum | string;
  rawType?: GroupTypeEnum | string;
  name: string;
  assetId?: string;
  serviceId?: string;
  role: string;
  isGroup?: boolean;
  isGlobal?: boolean;
  isAdmin?: boolean;
  isAssetServiceRole?: boolean;
  isHidden?: boolean;
}

@Injectable()
export class RolesService {
  constructor(
    protected keycloakService: KeycloakCustomService,
    private appConfig: AppConfigService,
    private groupControllerService: GroupControllerService
  ) {
  }

  /*private get ORG_CGDIS_ADMIN_ROLE_NAME(): string {
    return RolesService.getRoleName(ORG_NAME, GROUP_NAME_CGDIS, ADMIN_ROLE_NAME).toUpperCase();
  }*/

  private get ORG_MFEA_ADMIN_ROLE_NAME(): string {
    return RolesService.getRoleName(ORG_NAME, GROUP_NAME_MFEA, ADMIN_ROLE_NAME).toUpperCase();
  }

  // Temporary patch for adding user to the organization group if not belonging yet to any organization
  public doPatchUserRoleForHisDomainEmail(email: string, userId: string): void {
    if (this.keycloakService.getDecodedToken() && this.keycloakService.getDecodedToken().realm_access) {
      try {
        this.groupControllerService.findGroups(true, null, null, null).pipe(first()).subscribe((allGroups: Array<GroupDto>) => {
          //Search for domain corresponding to email
            this.groupControllerService.getDomains().subscribe((domains: { [p: string]: string }) => {
              if (domains && email) {
                email = email.toLowerCase().trim();
                for (let domain in domains) {
                  const ldomain: string = domain.trim().toLowerCase();
                  if (email.endsWith('@' + ldomain)) {//} || email.includes('@' + ldomain + '.')) {
                    const groupName: string = domains[domain];
                    const myGroups: GroupDto[] = globalThis.myGroups ? globalThis.myGroups : [];
                    //this.groupControllerService.findGroups(true, groupName, null, null).pipe(first()).subscribe((groups: Array<GroupDto>) => {
                      if (allGroups && allGroups.length > 0) {
                        const thisGroup: GroupDto = allGroups ? allGroups.find(group => group.name.toUpperCase() == groupName.toUpperCase() && group.groupType.toUpperCase() == ORG_NAME.toUpperCase() && !myGroups.find(item => myGroups.find(myGroup => myGroup.id == item.id))) : null;
                        if (thisGroup) {
                          this.groupControllerService.addUser(thisGroup.id, userId).subscribe(() => {
                            console.warn(' ##Hack done for ' + thisGroup.name);
                            this.keycloakService.renewTokenAndRefresh();
                          });
                        }
                      }
                    //})
                  }
              }
            }
            })
        });
      } catch (e) {
        console.error('##Error patching user role', e);
      }
    }
  }

  private getTokenRoles(): string[] {
    return this.keycloakService.getKeycloakInstance().tokenParsed.realm_access.roles;
  }

  public isFullAdmin(): boolean {
    const roles = this.getTokenRoles();
    return !roles || roles.length == 0 ? false : roles.includes(ADMIN_ROLE_NAME);
  }

  /*public isOrgCgdisAdmin(): boolean {
    const roles = this.getTokenRoles();
    return !roles || roles.length == 0 ? false : roles.includes(this.ORG_CGDIS_ADMIN_ROLE_NAME);
  }*/


  public isOrgMfeaAdmin(): boolean {
    const roles = this.getTokenRoles();
    return !roles || roles.length == 0 ? false : roles.includes(this.ORG_MFEA_ADMIN_ROLE_NAME);
  }

  public IsMemberOfThisGroup(groupName: string): boolean {
    if (groupName && groupName.trim().length > 0) {
      let groupNameUC: string = groupName.toUpperCase();
      for (let role of this.getTokenRoles()) {
        const roleLC: string = role.toUpperCase();
        let parts: Array<string> = roleLC.split(ROLE_SEPARATOR);
        if (parts.includes(groupNameUC)) {
          return true;
        }
      }
    }
    return false;
  }

  /*public isMyOrgCgdis(): boolean {
    let ret: boolean = (globalThis.currentOrganizationName && globalThis.currentOrganizationName.toUpperCase() == GROUP_NAME_CGDIS);
    if (!ret && !this.appConfig.isProduction()) {
      ret = true;
      console.warn('#You should not have access to ASSETS but you will have because you are on DEVELOPER MODE#');
    }
    return ret == true;
  }*/

  public isActionAllowedByRole(groupType: LeftMenuParamWindowType, groupName: string, action: GridActionEnum) {
    if (!groupType) {
      console.error('#Empty groupType: ', groupType);
      return false;
    }
    if (ACTIONS_FOR_QUERYING.includes(action) || ACTIONS_FOR_ANYONE.includes(action)) {
      return true;

    } else if (ACTIONS_FOR_MODIFYING.includes(action) && this.keycloakService.getKeycloakInstance() && this.keycloakService.getKeycloakInstance().tokenParsed && this.keycloakService.getKeycloakInstance().tokenParsed.realm_access && this.getTokenRoles()) {
      const groupNameLC: string = groupName ? groupName.toUpperCase() : null;
      for (let role of this.getTokenRoles()) {
        const roleLC: string = role.toUpperCase();
        if (roleLC == ADMIN_ROLE_NAME) {
          return true;
        }
        const groupTypeEnm = Utils.getGroupTypeEnum(groupType);
        if (action == GridActionEnum.singleCreateAction && roleLC == RolesService.getRoleName(groupTypeEnm, CREATOR_ROLE_NAME)) {
          return true;

        } else if (ACTIONS_FOR_MODIFYING.includes(action) && groupNameLC && roleLC == RolesService.getRoleName(groupTypeEnm, groupNameLC, ADMIN_ROLE_NAME)) {
          return true;

        }
      }
      return false;

    } else {
      // The rest of actions are automatically allowed
      return true;
    }
  }

  private static getRoleName(part1: string, part2?: string, part3?: string): string {
    if (!part2 && !part3) {
      return part1.toUpperCase();
    } else if (!part3) {
      return (part1 + ROLE_SEPARATOR + part2).toUpperCase();
    } else if (part1 && part2 && part3) {
      return (part1 + ROLE_SEPARATOR + part2 + ROLE_SEPARATOR + part3).toUpperCase();
    } else {
      console.error('#Error on getRoleName: not valid role name');
      return null;
    }
  }

  public static getTokenGroups(decodeToken: KeycloakTokenModel, showGroups: boolean, showGlobals: boolean, showAssetServiceRoles: boolean, translateService: TranslateService, allAssets: AssetDto[], myGroups: GroupDto[]): Array<KeycloakRolesDTO> {
    let array: Array<KeycloakRolesDTO> = new Array<KeycloakRolesDTO>();
    let decodedList: KeycloakTokenModel = decodeToken;
    if (decodedList && decodedList.realm_access && decodedList.realm_access.roles) {
      for (let item of decodedList.realm_access.roles) {
        if (!item.includes(OLD_ROLE_SEPARATOR)) {
          const role = RolesService.getTokenRole(item, translateService, allAssets, myGroups);
          if (!role.isHidden && ((showGroups && role.isGroup) || (showGlobals && role.isGlobal) || (showAssetServiceRoles && role.isAssetServiceRole))) {
            array.push(role);
          }
        }
      }
    }
    return array;
  }

  private static getTokenRole(item: string, translateService: TranslateService, allAssets: AssetDto[], myGroups: GroupDto[]): KeycloakRolesDTO {
    const itemUC: string = item.toUpperCase();
    let parts: Array<string> = item.split(ROLE_SEPARATOR);
    let ret: KeycloakRolesDTO = {
      type: parts.length > 0 ? parts[0] : '',
      rawType: parts.length > 0 ? parts[0] : '',
      name: parts.length > 1 ? parts[1] : '',
      role: parts.length > 2 ? parts[2] : ''
    }
    ret.isAdmin = parts.includes(ADMIN_ROLE_NAME);
    ret.isGroup = ret.type && [GroupTypeEnum.ORG, GroupTypeEnum.NET, GroupTypeEnum.TEAM].includes(ret.type as GroupTypeEnum);
    ret.isGlobal = !ret.isGroup && parts.length == 1;
    ret.isAssetServiceRole = !ret.isGroup && !ret.isGlobal;

    const retNameUC: string = ret.name.toUpperCase();
    const retTypeUC: string = ret.type.toUpperCase();
    if (ret.isGroup) {
      ret.kind = KeycloakKindEnum.Group;

      //Format the text
      if (translateService) {
        ret.type = translateService.instant(STATIC_I18N.COMMON.group_type_team.replace('team', ret.type));
        // ret.name = // TODO
      }
      ret.role = Utils.getStartCase(ret.role);
      if (myGroups) {
        const found = myGroups.find(item => item.name.toUpperCase() == retNameUC);
        if (found) {
          ret.name = found.name;
        }
      }

    } else if (allAssets && allAssets.length > 0 && ret.isAssetServiceRole) {
      const found = allAssets.find(item => item.assetId.toUpperCase() == retNameUC && item.assetType.toUpperCase() == retTypeUC);
      if (found) {
        if (found.physical) {
          ret.kind = KeycloakKindEnum.Asset;
        } else {
          ret.kind = KeycloakKindEnum.Service;
        }
        ret.name = found.name;
        ret.assetId = found.assetId;
        ret.type = found.assetType;
        ret.serviceId = found.serviceId;
        const roleFound = found.roles.find(item => item.toUpperCase() == ret.role.toUpperCase());
        ret.role = roleFound;
      }
    }

    // Exception list
    ret.isHidden = !ret.kind || [ADMIN_ROLE_NAME, FULL_ADMIN_CREATOR_ROLE_NAME, USER_ROLE_NAME].includes(item) || itemUC.startsWith('DEFAULT-ROLES'.toUpperCase()) || itemUC.startsWith('uma_auth');

    return ret;
  }
}
