/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {ArraySortPipe} from './core/directive/array-sort.directive';
import {VarDirective} from './core/directive/ng-var.directive';
import {NumbersOnlyInputDirective} from './core/directive/numbers-only-input.directive';
import {ImgLoadLazyDirective} from './core/directive/img-load-lazy.directive';
import {ImgLoadEagerDirective} from './core/directive/img-load-eager.directive';
import {InViewDirective} from './core/directive/in-view.directive';
import {FormFocusInvalidDirective} from './core/directive/form-focus-invalid.directive';
import {PrivacyPolicyComponent} from './pages/privacy_policy/privacy-policy/privacy-policy.component';
import {FlexModule} from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import {MatFileUploadModule} from 'mat-file-upload';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatOptionModule} from '@angular/material/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {NgForOf, NgIf} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MeterQuotaComponent} from './core/component/shared/meter-quota/meter-quota.component';
import {ImgLoadDeferDirective} from './core/directive/img-load-defer.directive';

const COMPONENTS_SUPER_SHARED = [
  MeterQuotaComponent
];

@NgModule({
  imports: [
    MatButtonModule,
    FlexModule,
    MatCardModule,
    MatFileUploadModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTabsModule,
    MatToolbarModule,
    NgForOf,
    NgIf,
    TranslateModule,
    MatTooltipModule
  ],
  declarations: [
    FormFocusInvalidDirective,
    NumbersOnlyInputDirective,
    ArraySortPipe,
    ImgLoadLazyDirective,
    ImgLoadEagerDirective,
    ImgLoadDeferDirective,
    InViewDirective,
    VarDirective,
    PrivacyPolicyComponent,
    ...COMPONENTS_SUPER_SHARED
  ],
  exports: [
    FormFocusInvalidDirective,
    NumbersOnlyInputDirective,
    ArraySortPipe,
    ReactiveFormsModule,
    FormsModule,
    ImgLoadLazyDirective,
    ImgLoadEagerDirective,
    ImgLoadDeferDirective,
    InViewDirective,
    VarDirective,
    PrivacyPolicyComponent,
    ...COMPONENTS_SUPER_SHARED
  ]
})

export class SharedModule {}
