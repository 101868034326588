export const environment = {
  // In order to user "require" is needed enable option "resolveJsonModule" on tsconfig.json
  // @ts-ignore
  version: '1.0.15-snapshot',
  timestampBuild: 'Thu Jan 16 2025 15:56:26 GMT+0000 (Coordinated Universal Time)',
  production: false,
  backendURL: 'https://central-user-mgmt.nextgen-lab.net',
  backendService: '/cumservice',
  announcementURL: 'https://central-user-mgmt.nextgen-lab.net',
  announcementService: '/announcementservice',
  widgetURL: 'https://central-user-mgmt.nextgen-lab.net',
  widgetService: '/widgetservice',
  whatsAppURL: 'https://central-user-mgmt.nextgen-lab.net',
  whatsappService: '/cumservice/whatsappgroup',
  communicationUrl: 'https://central-user-mgmt.nextgen-lab.net',
  communicationService: '/communicationservice',
  signalUrl: 'https://central-user-mgmt.nextgen-lab.net',
  signalService: '/signalservice',
  filesharingURL: 'https://central-user-mgmt.nextgen-lab.net',
  filesharingService: '/filesharingmiddleware',
  owncloudURL: 'https://share.nextgen-lab.net',
  wsURL: 'wss://central-user-mgmt.nextgen-lab.net',
  wsService: '/cumservice/notification',
  keycloakConfig: {
    redirectUri: 'https://central-user-mgmt.nextgen-lab.net',
    // url: 'https://10.20.44.48:18443/auth',
    // url: 'https://10.20.44.48:18080/auth',
    url: 'https://one-login.nextgen-lab.net',
    realm: 'emergency-test',
    clientId: 'mvp-test'
  },
  keycloakInitOptions: {
    onLoad: 'login-required', //Fix for error "Timeout when waiting for 3rd party check iframe message."
    checkLoginIframe: false
  }
};
