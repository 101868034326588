/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {Observable, of} from 'rxjs';
import {GridSourceEvents, IGridSource} from '../component/shared/grid/grid.component';
import {Utils} from '../services/tools/utils';
import {DataCSVDTO} from '../component/shared/dialog_invite_csv/invite-csv-dialog.component';
import {switchMap} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

export class InviteCSVGridSource extends GridSourceEvents implements IGridSource<DataCSVDTO, DataCSVDTO> {
  public readonly COMPONENT_TYPE: string = Utils.getClassName(this);
  public readonly COMPONENT_ID: string = this.COMPONENT_TYPE + '_' + new Date().getTime();

  private flipFlopObserver = new BehaviorSubject<any>(false);

  constructor(
    public dataCSV: Array<DataCSVDTO>
  ) {
    super();
  }

  updateDataGrid(params: any[]): void {
    this.dataCSV = params[0];
    this.flipFlopObserver.next(!this.flipFlopObserver.value);
  }

  postDataForGrid(dummy: any, orderBy: string, page: number, pageSize: number): Observable<Array<DataCSVDTO>> {
    return this.flipFlopObserver.asObservable().pipe(
      switchMap(variable => {
        return of(this.dataCSV);
      })
    );
  }
}
