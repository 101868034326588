import {Injectable} from '@angular/core';
// tslint:disable-next-line:no-submodule-imports
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppConfigService} from '../../../config/app-config.service';
import {Observable} from 'rxjs';
import {WidgetResponse} from '../model/WidgetResponse';
import {LogoResponse} from '../model/LogoResponse';
import {WidgetLinkResponse} from '../model/WidgetLinkResponse';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {

  protected basePath ;

  protected defaultHeaders = new HttpHeaders();

  private readonly BASE_FOLDER: string = 'widgets';

  private readonly LOGO: string = 'logo';

  private readonly LINK: string = 'link';

  constructor(protected httpClient: HttpClient, protected appConfigService: AppConfigService) {
    this.basePath = appConfigService.getWidgetService();
  }

  getAllWidgets(): Observable<Array<WidgetResponse>> {
    return  this.httpClient.get<Array<WidgetResponse>>(`${this.basePath}/${this.BASE_FOLDER}`);
  }

  getIconBylWidgetId(widgetId: number): Observable<LogoResponse> {
    return  this.httpClient.get<LogoResponse>(`${this.basePath}/${this.BASE_FOLDER}/${widgetId}/${this.LOGO}`);
  }

  getAllWidgetLinks(widgetId: number): Observable<Array<WidgetLinkResponse>> {
    return  this.httpClient.get<Array<WidgetLinkResponse>>(`${this.basePath}/${this.BASE_FOLDER}/${widgetId}/${this.LINK}`);
  }
}
